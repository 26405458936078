import React from "react";
import { AppProps } from "next/app";
import { HeaderComp } from "@app/components/HeaderComp";
import { FooterComp } from "@app/components/FooterComp";
import { Layout } from "@views/Layout";

import "@css/index.scss";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <div className="d-flex flex-column min-vh-100">
      <HeaderComp />
      <Layout>
        <Component {...pageProps} />
      </Layout>
      <FooterComp />
    </div>
  );
}

export default MyApp;
