import React from "react";
import Head from "next/head";
import { META_TITLE } from "../utilities/constants";

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="content font-play position-relative">
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{META_TITLE}</title>
      </Head>
      {children}
    </div>
  );
};
