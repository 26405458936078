export const IS_PROD = process.env.NODE_ENV === "production";
export const IS_DEV = process.env.NODE_ENV === "development";
export const IS_TEST = process.env.NODE_ENV === "test";

// Apps
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL || "https://vaditaslim.com";
export const APP_TITLE = process.env.NEXT_PUBLIC_APP_TITLE || "";

// Metas
export const META_URL = process.env.NEXT_PUBLIC_META_URL || APP_URL;
export const META_TITLE = process.env.NEXT_PUBLIC_META_TITLE || APP_TITLE;
export const META_DESCRIPTION = process.env.NEXT_PUBLIC_META_DESCRIPTION || "";
export const META_TWITTER = process.env.NEXT_PUBLIC_META_TWITTER || "@vaditaslim";
export const META_KEYWORDS = process.env.NEXT_PUBLIC_META_KEYWORDS || "";

// Socials
export const SOCIAL_GITHUB = process.env.NEXT_PUBLIC_APP_SOCIAL_GITHUB || "";
export const SOCIAL_CODEPEN = process.env.NEXT_PUBLIC_APP_SOCIAL_CODEPEN || "";
export const SOCIAL_TWITTER = process.env.NEXT_PUBLIC_APP_SOCIAL_TWITTER || "";
export const SOCIAL_STACKOVERFLOW = process.env.NEXT_PUBLIC_APP_SOCIAL_STACKOVERFLOW || "";
export const SOCIAL_FACEBOOK = process.env.NEXT_PUBLIC_APP_SOCIAL_FACEBOOK || "";
export const SOCIAL_LINKEDIN = process.env.NEXT_PUBLIC_APP_SOCIAL_LINKEDIN || "";
export const SOCIAL_IG = process.env.NEXT_PUBLIC_APP_SOCIAL_IG || "";

// Contacts
export const CONTACT_EMAIL = process.env.NEXT_PUBLIC_APP_CONTACT_EMAIL || "choz.au7@gmail.com";

// Analytics
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_APP_GA_MEASUREMENT || "";

// API Endpoints
export const GRAPHCMS_URL = process.env.NEXT_PUBLIC_APP_GRAPHCMS_URL || "";
export const GRAPHCMS_TOKEN = process.env.GRAPHCMS_TOKEN || "";

// OpenAI
export const OPENAI_KEY = process.env.OPENAI_KEY || "";
