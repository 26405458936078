import React, { useEffect, useRef, useState } from "react";
import { useWindowScroll } from "react-use";
import logo from "@assets/images/logo.svg";

export const HeaderComp: React.FC = () => {
  const [navbarToggled, setNavbarToggled] = useState(false),
    [navHidden, setNavHidden] = useState(false),
    sideNavRef = useRef<HTMLElement>(),
    navToggleRef = useRef<HTMLDivElement>(),
    prevScrollY = useRef(0),
    { y: scrollY } = useWindowScroll();

  const toggleNavbar = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    setNavbarToggled(!navbarToggled);
  };

  useEffect(() => {
    if (prevScrollY.current > scrollY) {
      /* Scrolling up */
      setNavHidden(false);
    } else if (prevScrollY.current < scrollY && scrollY > 100) {
      setNavHidden(true);
      setNavbarToggled(false);
    }

    prevScrollY.current = window.scrollY;
  }, [scrollY]);

  useEffect(() => {
    if (sideNavRef.current && navToggleRef.current) {
      const onClickOutside = (evt) => {
        const target = (evt && evt.target) as HTMLElement,
          isSidenavTapped = sideNavRef && sideNavRef.current.contains(target),
          isToggleBtnTapped = navToggleRef && navToggleRef.current.contains(target),
          isLink = evt && target && target.classList && target.classList.contains("nav-jumper-link");

        if (!isToggleBtnTapped && !isSidenavTapped && navbarToggled) {
          setNavbarToggled(false);
        } else if (isLink) {
          setNavbarToggled(false);
        }
      };

      document.addEventListener("mousedown", onClickOutside);

      return () => {
        document.removeEventListener("mousedown", onClickOutside);
      };
    }
  }, []);

  return (
    <div id="header" className={"container-fluid px-3 py-3 py-sm-4 py-sm-3 fixed-top" + (navHidden ? " hidden" : "")}>
      <div className="row">
        <div className="col-3 col-sm-2 col-lg-1 align-items-center justify-content-center">
          <img src={logo.src} className="logo d-block mx-auto mx-sm-0" alt="Logo" width={42} height={42} />
        </div>

        <div className="col-9 col-sm-10 col-lg-11 d-flex align-items-center justify-content-end justify-content-sm-end mb-3 mb-sm-0">
          {/* Desktop Menu */}
          <ol className="nav list-inline d-none d-sm-flex">
            <li>
              <a href="#about-me" className="link">
                About
              </a>
            </li>
            <li>
              <a href="#experience" className="link">
                Experience
              </a>
            </li>
            <li>
              <a href="#projects" className="link">
                Projects
              </a>
            </li>
            <li>
              <a href="#contact" className="link">
                Contact
              </a>
            </li>
            <li className="no-counter">
              <a href="/static/attachments/profile.pdf" download="profile.pdf" className="btn btn-outline-primary px-3 py-1">
                <i className="fa fa-download me-2"></i>Profile
              </a>
            </li>
          </ol>

          {/* Mobile Menu */}
          <div ref={navToggleRef} className="d-flex d-sm-none burger">
            <button type="button" className={"navbar-toggle " + (navbarToggled ? "expanded" : "collapsed")} data-target="#navbar-collapse" onClick={toggleNavbar}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>

          <div className={"d-flex d-sm-none flying-menu" + (navbarToggled ? " show" : "")}>
            <aside ref={sideNavRef} className="menu d-flex flex-column justify-content-center align-items-center">
              <ol className="nav list-inline d-flex flex-column">
                <li>
                  <a href="#about-me" className="link nav-jumper-link">
                    About
                  </a>
                </li>
                <li>
                  <a href="#experience" className="link nav-jumper-link">
                    Experience
                  </a>
                </li>
                <li>
                  <a href="#projects" className="link nav-jumper-link">
                    Projects
                  </a>
                </li>
                <li>
                  <a href="#contact" className="link nav-jumper-link">
                    Contact
                  </a>
                </li>
              </ol>
              <div className="mt-5">
                <a href="/static/attachments/profile.pdf" download="profile.pdf" className="btn btn-outline-primary px-3 py-1">
                  <i className="fa fa-download me-2"></i>Profile
                </a>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};
