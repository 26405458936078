import React from "react";
import { SOCIAL_GITHUB, SOCIAL_CODEPEN, SOCIAL_TWITTER, SOCIAL_STACKOVERFLOW, SOCIAL_FACEBOOK, SOCIAL_LINKEDIN, SOCIAL_IG, CONTACT_EMAIL } from "@app/utilities/constants";

export function FooterComp() {
  return (
    <div id="footer" className="container-fluid p-3">
      <div className="row">
        <div className="col text-center">
          <p className="small font-weight-bold"> Thanks for scrolling! </p>
          <ul className="list-inline d-flex justify-content-center">
            <li>
              <a href={SOCIAL_GITHUB} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-github"></i>
              </a>
            </li>
            <li>
              <a href={SOCIAL_IG} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href={SOCIAL_FACEBOOK} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href={SOCIAL_TWITTER} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href={SOCIAL_STACKOVERFLOW} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-stack-overflow"></i>
              </a>
            </li>
            <li>
              <a href={SOCIAL_CODEPEN} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-codepen"></i>
              </a>
            </li>
            <li>
              <a href={SOCIAL_LINKEDIN} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
          </ul>
          <p className="small"> Built by Vadi </p>
        </div>
      </div>

      <div className="bottom-nav d-none d-sm-block">
        <div className="left">
          <ul className="list-inline d-flex flex-column justify-content-center mb-4">
            <li className="p-0 m-0">
              <a href={SOCIAL_GITHUB} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-github"></i>
              </a>
            </li>
            <li className="p-0 m-0">
              <a href={SOCIAL_TWITTER} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li className="p-0 m-0">
              <a href={SOCIAL_STACKOVERFLOW} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-stack-overflow"></i>
              </a>
            </li>
            <li className="p-0 m-0">
              <a href={SOCIAL_CODEPEN} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-codepen"></i>
              </a>
            </li>
            <li className="p-0 m-0">
              <a href={SOCIAL_LINKEDIN} target="_blank" rel="noreferrer noopener">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="right d-flex flex-row p-0 m-0">
          <a href={"mailto:" + CONTACT_EMAIL} className="mb-4">
            {CONTACT_EMAIL}
          </a>
        </div>
      </div>
    </div>
  );
}
